






































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MintViewModel } from '../viewmodels/mint-viewmodel'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Inject({}) vm!: MintViewModel
  wallet = walletStore

  goToProfileNft() {
    if (this.wallet?.userProfile?.unique_id)
      this.$router.push(`/profile/${this.wallet.userProfile?.unique_id}/nft?nft_tab=mystery-box`)
  }
}
